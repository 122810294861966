import { authHeader } from './auth-header';
import axios from "axios";

export const userService = {
    login,
    logout,
    register,
    getAll,
};

function login(email, password) {

    const url = 'https://organiks-246f53a8d7da.herokuapp.com/api/auth/login';
    const data = { email, password };

    console.log("login data",data)
    const formData = new FormData();
    formData.append('email', email);
    formData.append('password', password);

    return axios.post(url, formData)//both formData and data can work here
        .then(response => {
            // Check if the authentication was successful
            const responseData = response.data;

            if (responseData.isSuccessful) {
                // Extract user data and access token
                const { user, access_token } = responseData.data;

                // Store user details and access token in local storage
                localStorage.setItem('user', JSON.stringify(user));
                localStorage.setItem('access_token', access_token);

                // Return user data
                return user;
            } else {
                // Authentication was not successful, handle the error here
                throw new Error(responseData.message || 'Authentication failed');
            }
        })
        .catch(error => {
            // Handle Axios request or other errors
            throw error;
        });
}

function logout() {
    // remove user from local storage to log user out
    localStorage.removeItem('user');
}

function register(user) {
    const url = 'https://organiks-246f53a8d7da.herokuapp.com/api/auth/register';
    var name = user.name
    var email = user.email
    var password = user.password
    var password_confirmation = user.password_confirmation

    const data = { name, email, password, password_confirmation };
    const registrationFormData = new FormData();
    registrationFormData.append('name', name);
    registrationFormData.append('email', email);
    registrationFormData.append('password', password);
    registrationFormData.append('password_confirmation', password_confirmation);

    console.log("xxxda", data)
    return axios.post(url, registrationFormData)//both formData and data can work here
        .then(response => {
            // Check if the authentication was successful
            const responseData = response.data;

            if (responseData.isSuccessful) {
                // Extract user data and access token
                const { user, access_token } = responseData.data;

                // Store user details and access token in local storage
                localStorage.setItem('user', JSON.stringify(user));
                localStorage.setItem('access_token', access_token);

                // Return user data
                return user;
            } else {
                // Authentication was not successful, handle the error here
                throw new Error(responseData.message || 'Authentication failed');
            }
        })
        .catch(error => {
            // Handle Axios request or other errors
            throw error;
        });
}

function getAll() {
    const requestOptions = {
        method: 'GET',
        headers: authHeader()
    };
    return fetch(`/users`, requestOptions).then(handleResponse);
}

function handleResponse(response) {
    return response.text().then(text => {
        const data = text && JSON.parse(text);
        if (!response.ok) {
            if (response.status === 401) {
                // auto logout if 401 response returned from api
                logout();
                location.reload(true);
            }
            const error = (data && data.message) || response.statusText;
            return Promise.reject(error);
        }
        return data;
    });
}
